<template>
  <div>
    <div class="date">
      <ul>
        <li
          v-for="(item, index) in dateArr"
          :key="index"
          :class="dateIndex === index ? 'cur' : ''"
          @click="changeDate(item, index)"
        >
          <span>{{ item.week }}</span>
          <br />
          {{ item.date }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'Index',

    data() {
      return {
        dateIndex: 0,
        dateArr: [],
        isAll: false,
      }
    },
    mounted() {
      this.makeDate()
    },

    methods: {
      //改变时间
      changeDate(item, index) {
        this.dateIndex = index
        console.log(item)
        this.$emit('getDate', item)
      },

      //组装时间
      async makeDate() {
        let data = JSON.parse(sessionStorage.getItem('day'))
        this.day = data.day
        let arr = []
        let weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        for (let i = 0; i < this.day; i++) {
          let date = moment(new Date())
            .add(i, 'day')
            .format('MM-DD')
          let dateAll = moment(new Date())
            .add(i, 'day')
            .format('yyyy-MM-DD')
          let index = moment(dateAll).format('E') - 1
          let week = weeks[index]
          let obj = {
            week: week,
            date: date,
            dateAll: dateAll,
          }
          arr.push(obj)
        }
        this.dateArr = arr
      },

      setData(day) {
        this.day = day
      },
    },
  }
</script>

<style scoped>
  .date {
    width: 95%;
    margin: auto;
    overflow: auto;
    display: block;
    background: #fff;
    margin-top: 0.4rem;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
  }
  .date ul {
    width: max-content;
  }
  .date ul li {
    float: left;
    list-style: none;
    font-size: 0.42rem;
    line-height: 1.2;
    text-align: center;
    padding: 5px 10px;
    box-sizing: content-box;
  }
  .date ul li span {
    color: #666;
  }
  .date ul li.cur {
    color: #fff;
    background: #1989fa;
    border-radius: 10px;
  }

  .date ul li.cur span {
    color: rgb(102, 203, 248);
  }
</style>
