<template>
  <div>
    <DateSelectDay @getDate="getDate" />
    <div class="docList">
      <van-cell-group v-if="list.length > 0">
        <van-cell v-for="(item, index) in list" :key="index">
          <van-card>
            <template #title>
              <span class="name">
                {{ item.doctorName }}
              </span>
              &nbsp;
              <span class="job">{{ item.title }}</span>
              <van-tag type="danger">{{ item.reason }}</van-tag>
              <div class="number">
                {{ item.dateTypeName }}
              </div>
              <br />
              <span class="dept">
                {{ item.deptName }}
              </span>
              <br />
            </template>
            <template #thumb>
              <van-image
                v-if="item.picture !== ''"
                round
                width="2rem"
                height="2rem"
                fit="contain"
                :src="'data:image/png;base64,' + item.picture"
              />
              <van-image
                v-else
                round
                width="2rem"
                height="2rem"
                :src="require('@/assets/dept_images/Doctor_photo.png')"
              />
            </template>
            <template #desc>
              <p class="desc">
                <van-image
                  width="0.8rem"
                  :src="require('@/assets/dept_images/sc.png')"
                />
                {{ item.des }}
              </p>
            </template>
          </van-card>
        </van-cell>
      </van-cell-group>
      <van-empty v-else description="暂无医生停诊，请重新查询" />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { Toast } from 'vant'
  import DateSelectDay from '@/components/DateSelectDay'
  import { getDoctorCloseList } from '@/api/his/his'

  export default {
    name: 'DoctorClose',
    components: { DateSelectDay },
    data() {
      return {
        radio: 2,
        day: 14,
        search: {
          BeginTime: '',
          EndTime: '',
        },
        list: [],
      }
    },
    async created() {
      await this.pageInit()
      await this.fetchData()
    },
    mounted() {},
    methods: {
      //初始化
      async pageInit() {
        this.search.BeginTime = moment(new Date()).format('yyyy-MM-DD')
        this.search.EndTime = moment(new Date()).format('yyyy-MM-DD')
      },
      //列表获取
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getDoctorCloseList(this.search)
        this.list = data
        Toast.clear()
      },
      //时间点击时间
      getDate(obj) {
        let date = obj.dateAll
        this.search.BeginTime = moment(date).format('yyyy-MM-DD')
        this.search.EndTime = moment(date).format('yyyy-MM-DD')
        this.fetchData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .van-dropdown-menu {
    width: 95%;
    margin: auto;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 10px;
    ::v-deep .van-dropdown-item {
      top: 48px !important;
      width: 95%;
      margin: auto;
      .van-dropdown-item__content {
        height: 100vh;
        max-height: none;
      }
    }
  }
  .docList {
    width: 95%;
    margin: auto;
    margin-top: 0.4rem;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      padding: 0.426rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
    }
    .van-card {
      background: #fff;
      font-size: 0.426rem;
      line-height: 1.5;
      .name {
        font-weight: bold;
      }
      .job {
        font-size: 0.38rem;
        color: #616161;
      }
      .dept {
        color: #616161;
      }
      .number {
        background: #1691fe;
        width: fit-content;
        padding: 0 7px;
        color: #fff;
        line-height: 30px;
        border-radius: 15px;
        float: right;
      }
      .desc {
        margin: 0;
        font-size: 0.38rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .van-dropdown-menu {
    ::v-deep .van-dropdown-menu__title--active {
      color: #1691fe;
    }
  }
</style>
